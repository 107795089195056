// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-unit-1-mdx": () => import("./../src/pages/unit-1.mdx" /* webpackChunkName: "component---src-pages-unit-1-mdx" */),
  "component---src-pages-unit-10-mdx": () => import("./../src/pages/unit-10.mdx" /* webpackChunkName: "component---src-pages-unit-10-mdx" */),
  "component---src-pages-unit-11-12-mdx": () => import("./../src/pages/unit-11+12.mdx" /* webpackChunkName: "component---src-pages-unit-11-12-mdx" */),
  "component---src-pages-unit-13-mdx": () => import("./../src/pages/unit-13.mdx" /* webpackChunkName: "component---src-pages-unit-13-mdx" */),
  "component---src-pages-unit-2-mdx": () => import("./../src/pages/unit-2.mdx" /* webpackChunkName: "component---src-pages-unit-2-mdx" */),
  "component---src-pages-unit-3-mdx": () => import("./../src/pages/unit-3.mdx" /* webpackChunkName: "component---src-pages-unit-3-mdx" */),
  "component---src-pages-unit-4-mdx": () => import("./../src/pages/unit-4.mdx" /* webpackChunkName: "component---src-pages-unit-4-mdx" */),
  "component---src-pages-unit-5-mdx": () => import("./../src/pages/unit-5.mdx" /* webpackChunkName: "component---src-pages-unit-5-mdx" */),
  "component---src-pages-unit-6-mdx": () => import("./../src/pages/unit-6.mdx" /* webpackChunkName: "component---src-pages-unit-6-mdx" */),
  "component---src-pages-unit-7-mdx": () => import("./../src/pages/unit-7.mdx" /* webpackChunkName: "component---src-pages-unit-7-mdx" */),
  "component---src-pages-unit-8-9-mdx": () => import("./../src/pages/unit-8+9.mdx" /* webpackChunkName: "component---src-pages-unit-8-9-mdx" */)
}

